@import '../../../../scss/theme-bootstrap';

.complementary-benefit-formatter {
  align-items: center;
  width: 100%;
  @include breakpoint($landscape-up) {
    display: flex;
  }
  &__carousel {
    width: 100%;
    height: 186px;
    @include breakpoint($landscape-up) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 320px;
    }
    &.slick-slider {
      margin-bottom: 0;
    }
    &-item {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
        flex: 33.33%;
      }
      &:nth-child(3n + 2) {
        .complementary-benefit {
          @include breakpoint($landscape-up) {
            border-#{$ldirection}: 1px solid $color-cream-stroke;
            border-#{$rdirection}: 1px solid $color-cream-stroke;
          }
        }
      }
    }
    .slick-list {
      &.draggable {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .carousel-controls,
  .carousel-dots {
    .slick-dots {
      text-align: $rdirection;
      padding-#{$rdirection}: 20px;
      bottom: 0;
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}
